import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/Layouts/Layout';
import SEO from '../components/seo';

// get all unique values
const getUnique = (events, value) => {
  return [...new Set(events.map(event => event[value]))];
};

function Gallery({ data }) {
  const { nodes } = data.allEventsJson;
  const [events, setEvents] = useState(nodes);
  const [type, setType] = useState('All Events');
  const [year, setYear] = useState('All Years');
  const [sortedEvents, setSortedEvents] = useState(nodes);
  const years = ['All Years', ...getUnique(nodes, 'year')];
  const types = ['All Events', ...getUnique(events, 'type')];

  const filterEvents = () => {
    // all the events
    let tempEvents = [...events];

    // filter by type
    if (type !== 'All Events') {
      tempEvents = tempEvents.filter(event => event.type === type);
    }

    // filter by year
    if (year !== 'All Years') {
      tempEvents = tempEvents.filter(event => event.year === parseInt(year));
    }

    setSortedEvents(tempEvents);
  };

  useEffect(() => {
    filterEvents();
  }, [type, year]);

  const handleChange = e => {
    if (e.target.name === 'type') {
      setType(e.target.value);
    }
    if (e.target.name === 'year') {
      setYear(e.target.value);
    }
  };

  return (
    <Layout>
      <SEO title='Gallery' />
      <section className='pb-20 relative block min-h-screen'>
        <div className='container mx-auto px-4'>
          <section className='mt-24'>
            <div className='text-white px-6 sm:px-6 py-4 border-0 rounded relative mb-4 bg-blue-600'>
              <div className='flex sm:justify-between justify-between items-center'>
                <span className='text-xl inline-block mr-5 align-middle gallery-filter-icon'>
                  <i className='fas fa-filter'></i>
                </span>
                <form>
                  <div className='inline-flex sm:mb-4'>
                    <select
                      name='type'
                      className='border border-gray-300 rounded text-gray-700 h-10 hover:border-gray-400 focus:outline-none appearance-none mr-4'
                      onChange={handleChange}
                      value={type}
                    >
                      {types.map(option => (
                        <option>{option}</option>
                      ))}
                    </select>
                  </div>
                  <div className='inline-flex'>
                    <select
                      name='year'
                      className='border border-gray-300 rounded text-gray-700 h-10 hover:border-gray-400 focus:outline-none appearance-none'
                      onChange={handleChange}
                      value={year}
                    >
                      {years.map(option => (
                        <option>{option}</option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </section>
          {sortedEvents.length <= 0 && <h2>No Events Found</h2>}
          <div className='flex flex-wrap'>
            {sortedEvents.map(({ id, title, date, src, slug }) => (
              <div className='w-full sm:w-6/12 lg:w-3/12 px-2' key={id}>
                <div
                  className='relative flex flex-col min-w-0 break-words bg-white w-full mb-4 shadow-lg rounded-lg bg-blue-600 text-center'
                  style={{ minHeight: '22rem' }}
                >
                  <GatsbyImage
                    image={src.childImageSharp.gatsbyImageData}
                    alt={title}
                    style={{ maxHeight: '12rem' }}
                    className='w-full align-middle rounded-t-lg'
                  />
                  <blockquote className='relative p-4 mb-2'>
                    <svg
                      preserveAspectRatio='none'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 583 95'
                      className='absolute left-0 w-full block h-95-px -top-94-px'
                    >
                      <polygon
                        points='-30,95 583,95 583,65'
                        className='text-blue-600 fill-current'
                      />
                    </svg>
                    <h4 className='text-xl font-normal text-white'>{title}</h4>
                    <p className='text-md font-normal mt-2 mb-4 text-gray-400'>
                      {date}
                    </p>
                    <Link
                      to={`/gallery/${slug}`}
                      className='bg-orange-500 text-white active:bg-orange-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
                    >
                      <i className='fas fa-camera' /> Photos
                    </Link>
                  </blockquote>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

Gallery.propTypes = {
  data: PropTypes.array,
};

export default Gallery;

export const AllEvents = graphql`
  query events {
    allEventsJson {
      nodes {
        id
        title
        date
        year
        type
        slug
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
